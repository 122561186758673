$('.eh-shop-the-story-v1,.eh-product .btn-quickbuy').on('click', function(e) {
  if($('body,article').find('.view-details.btn-add-to-bag')){
    $(this).ajaxSuccess(function(e, xhr, opt){
      var translated = "詳しくはこちら";
      $(".view-details.btn-add-to-bag").text(translated);
    });
  }
});

$(document).ready(function () {
  var $div = $("#BVSubmissionContainer");
  if($div.length) {
    var observer = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
        if (mutation.attributeName === "class") {
          var attributeValue = $(mutation.target).prop(mutation.attributeName);
          // console.log("Class attribute changed to:", attributeValue);
          if( attributeValue == 'BVSubmissionInProgress') {
            var BVTextShort = $("span.BVSubjectDescription span.BVTextShort").html();
            $("span.BVSubjectDescription span.BVTextShort").html($("<textarea />").html(BVTextShort).text());
            var BVTextLong = $("span.BVSubjectDescription span.BVTextLong").html();
            $("span.BVSubjectDescription span.BVTextLong").html($("<textarea />").html(BVTextLong).text());
          }
        }
      });
    });

    observer.observe($div[0],  {
      attributes: true
    });
  }
  //price format change in shop the story wink page & Index page
  $('body').on('click', '#index-page-wrapper .indexpage-content', function(e){
    Price_format($('.eh-shop-the-story-v1 .products .product-info .product-price'));
  });
  if($('.shop_the_story').length){
    Price_format($('.shop_the_story .slides li .products .product-info .product-price,.eh-product .product-info .product-price'));
  }
});
function Price_format(selector){
  var tax_excluded = $("[data-localtrans=tax_excluded]").text();
  var ProductPrice = selector;
    ProductPrice.each(function(idx, li) {
      var Price = $(li).html();
      var modified_price = Price.replace(/[^a-zA-Z0-9-,]/g, '');
      modified_price += tax_excluded;
      $(li).text(modified_price);
    });
}

// APACFECX-2102 - Fixing on the div alignment
Drupal.behaviors.ehContentFormatterV2	= {
  attach: function (context, settings) {
    $('.eh-content-formatter', context).each(function () {
      var thisFormatter = $(this);
      thisFormatter.filter('.see-all').find('.slides > li:nth-child(3n+1)').addClass('clear');
    });
  }
}